<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img src="@/assets/images/icons8-people-96.png" class="h-20 ml-2 inline-block" />
        سایر گروه ها
      </h2>
      <p class="mt-3">گروه هایی که در آن عضو هستید</p>
    </template>
    <template #default>
      <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        <div
          v-for="group in groups"
          :key="group.id"
          class="px-5 bg-blueGray-100 border-blueGray-200 border rounded-md"
        >
          <h2 class="text-lg font-bold mt-5 mb-3">{{ group.name }}</h2>
          <div class="grid grid-cols-2 p-1">
            <div>ID</div>
            <div class="text-left" dir="ltr">{{ group.gid }}</div>
          </div>
          <div class="grid grid-cols-2 p-1">
            <div>ممبر</div>
            <div class="text-left" dir="ltr">{{ group.members }}</div>
          </div>
          <div class="grid grid-cols-2 p-1">
            <div>اشتراک</div>
            <div class="text-left">
              {{ group.default ? "رایگان" : group.setting_name || "رایگان" }}
              <span v-if="group.setting_name">
                {{ group.setting_exp ? "(" + group.setting_exp + " روز)" : "همیشه" }}
              </span>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 mt-2 mb-4">
            <Button
              :to="{
                name: 'GroupUserInfo',
                params: { id: $route.params.id, groupId: group.id },
              }"
              class="text-sm h-10 lg:text-lg"
              color="sky"
            >
              بیشتر
            </Button>
            <Button
              title=""
              :to="{
                name: 'GroupUserAccounts',
                params: { id: $route.params.id, groupId: group.id },
              }"
              class="text-sm h-10 lg:text-lg"
              color="green"
            >
              خرید اشتراک
            </Button>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Button from "@/components/Button.vue";
import _ from "lodash";

export default {
  components: {
    BodySimple,
    Button,
  },
  data() {
    return {
      firstLoading: true,
      groups: [],
      L_: _,
    };
  },
  mounted() {
    let $this = this;
    // console.log($this.$store.state.user.id);
    $this.$axios
      .get("/api/user-groups", {
        params: {
          id: $this.$store.state.user.id,
        },
      })
      .then(function (response) {
        // console.log(response);
        $this.groups = response.data.groups;
      })
      .catch(function (error) {
        // console.log(error);
      })
      .finally(function () {
        // always executed
        $this.firstLoading = false;
      });
  },
};
</script>

<style></style>
